.flex-container {
    display: flex;
    background-color: #f1f1f1;
    flex-wrap: wrap;
}

.flex-container > div {
    background-color: DodgerBlue;
    color: white;
    width: 31%;
    height: 400px;
    margin: 1%;
    line-height: 75px;
    display: flex;
    flex-direction: column;
}

.flex-container > div .topArea{
    background-color: lightgray;
    color: white;
    width: 100%;
    height:85%;
}

.flex-container > div .bottomArea{
    background-color: orange;
    color: white;
    width: 100%;
    height: 15%;
}

@media (max-width: 800px) {
    .flex-container > div {
        background-color: DodgerBlue;
        color: white;
        width: 100%;
        height: 310px;
        margin: 1%;
        line-height: 75px;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (min-width: 800px) and (max-width: 2000px) {
    .flex-container > div {
        background-color: DodgerBlue;
        color: white;
        width: 50%;
        height: 310px;
        margin: 1%;
        line-height: 75px;
        display: flex;
        flex-direction: column;
    }
}